<template>
  <div>
    <div class="product-inner">
      
      <div  v-if="useModal === false">
        <router-link :to="{ name: 'ProductDetails', params: { id:product.id.toString() } }" append >
          <div class="product-thumb">
            <div class="thumb-inner">
              <img :src="(product.image !== undefined && product.image !== null)?`${imageUrl(product.image)}`:'/assets/images/ph-product.png'" class="modal-img" alt="img" />
            </div>
          </div>
        </router-link>
      </div>

      <div  v-else @click="emitir(product.id)" class="puntero">
        <div class="product-thumb">
          <div class="thumb-inner">
            <img :src="(product.image !== undefined && product.image !== null)?`${imageUrl(product.image)}`:'/assets/images/ph-product.png'" class="modal-img" alt="img" />
          </div>
        </div>
      </div>
      
      
      <div class="product-info">
        <div  v-if="useModal === false">
          <router-link :to="{ name: 'ProductDetails', params: { id:product.id.toString() } }" append >
            <h5 class="product-name product_title fluxtext"> {{ product.name }} </h5>
          </router-link>
        </div>

        <div  v-else @click="emitir(product.id)" class="puntero">
            <h5 class="product-name product_title fluxtext"> {{ product.name }} </h5>
        </div>

        <div class="group-info">
          <div class="stars-rating">
            <div class="count-star">
                <span v-show="product.code!=null">
                ( # {{(product.code!== undefined)?product.code:''}} )
                </span>
            </div>
          </div>
          <div v-if="showPrice" class="price">
            <ins v-if="configuration.show_pricelists==0">
                $ {{ (product.prices!== undefined)?product.prices[0]:'' }}
            </ins>

            <ins v-if="configuration.show_pricelists==1">
                ${{ (product.prices!== undefined)?product.prices[0]:'' }} (Min)&ensp;
            </ins>
            <may v-if="configuration.show_pricelists==1">
                ${{ (product.prices!== undefined)?product.prices[1]:'' }} (May)
            </may>
          </div>
        </div>
      </div>
      <div class="btn-container">
        <button type="button" :disabled="!productReady()" class="single_add_to_cart_button button buttonreal" v-bind:class="{'disabledInput': !productReady()}" @click="addToBasket()">
          Agregar&nbsp;al&nbsp;carrito
        </button>
        <span class="single_add_to_cart_button button boton">
          Agregar&nbsp;al&nbsp;carrito
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  data(){
    return {
    selectedItem: {
        key:0,
        product: this.product,
        amount: 1,
        variant: {},
        pricelist:0,
      }
    }
  },
  props: {
    product: {
      type: Object,
    },
    useModal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      user: "ecommerce/user",
      configuration: "ecommerce/configuration"
    }),
    showPrice: {
        get: function () {
            if(this.configuration.show_public_prices){
                return true;
            }else{
                if(this.user!=null){
                    return true;
                }
            }
            return false;
        }
    }
  },
  methods: {
    ...mapMutations({
      addToBasketMutator: 'ecommerce/ADD_TO_BASKET',
    }),
    imageUrl(image) {
      const arr = image.split('.');
      const ext = arr.pop();
      return arr.join('.')+'_card.'+ext
    },
    emitir(id) {
      if (this.useModal) {
        this.$emit('open-detail-modal', id)
      }
    },
    addToBasket() {
      this.selectedItem.key = String(this.selectedItem.product.id)
      this.addToBasketMutator(this.selectedItem)
      const button = event.target
      const contenedor = button.closest("div");
      const buttonspan = contenedor.querySelector(".boton");
      buttonspan.classList.add('btnanimation')

      setTimeout(() => {
        buttonspan.classList.remove('btnanimation')
      }, 300);
    },
    productReady(){
        if(this.configuration.variant_check && this.variantExists==true>0){
            if(Object.entries(this.selectedItem.variant).length!==0){
                if(this.configuration.sale_without_stock){
                    return true;
                }else{
                    if(this.selectedItem.amount<=this.selectedItem.variant.stock){
                        return true;
                    }
                }
            }
        }else{
            if(this.configuration.sale_without_stock){
                return true;
            }else{
                if(this.selectedItem.amount<=this.product.stock){
                    return true;
                }
            }
        }

        return false;
    }
  }
};
</script>

<style scoped>
.puntero {
  cursor: pointer;
}
.btn-container {
  display: flex;
  position: relative;
  justify-content: center;
  margin-top: 1.25em;
  margin-bottom: 0.5em;
}

.single_add_to_cart_button:hover {
  background-color: var(--accent-color-dark);
}
.single_add_to_cart_button:active {
  background-color: var(--accent-color-light);
}
.modal-img{
  height: 200px;
  width: 300px;
  object-fit: contain;

}

.buttonreal {
  position: absolute;
  top: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 1.25em;
  z-index: 10;
}
.fluxtext {
  width: 190px;
  margin: 0 auto;
  margin-bottom: 4px;
  white-space: nowrap;
  
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
@media (max-width: 480px) {
  .modal-img {
    height: 50%;
  }
  .product-inner button,
  .product-inner .boton {
    padding: 15px;
  }
  .fluxtext {
    width: 120px;
    white-space: wrap;
    overflow: auto;
    -o-text-overflow: unset;
    text-overflow: unset;
  }
  .buttonreal {
  top: 12px;

}
}
.button::before {
  transition: opacity 0.2s ease-in-out, transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.btnanimation {
  animation: addCartAnimation 0.3s ease-in-out;
}
@keyframes addCartAnimation {
  0% {
    transform: translateY(0) translateX(0) scale(1);
    opacity: 1;
  }
  10% {
    transform: translateY(-15px) translateX(10px) scale(0.95);
    opacity: 0.9;
  }
  20% {
    transform: translateY(-30px) translateX(20px) scale(0.9);
    opacity: 0.8;
  }
  30% {
    transform: translateY(-45px) translateX(30px) scale(0.8);
    opacity: 0.7;
  }
  40% {
    transform: translateY(-60px) translateX(40px) scale(0.7);
    opacity: 0.6;
  }
  50% {
    transform: translateY(-75px) translateX(50px) scale(0.6);
    opacity: 0.5;
  }
  60% {
    transform: translateY(-90px) translateX(60px) scale(0.5);
    opacity: 0.45;
  }
  70% {
    transform: translateY(-105px) translateX(70px) scale(0.4);
    opacity: 0.3;
  }
  80% {
    transform: translateY(-120px) translateX(80px) scale(0.3);
    opacity: 0.2;
  }
  90% {
    transform: translateY(-135px) translateX(90px) scale(0.2);
    opacity: 0.1;
  }
  100% {
    transform: translateY(-150px) translateX(100px) scale(0.1);
    opacity: 0;
  }
}
</style>
