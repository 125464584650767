<template>
  <div class="shop-top-control">
    <form class="select-item select-form">
      <span class="title">Mostrando productos</span>
      <select v-model="itemCount" class="chosen-select" @change="changeQuantity">
        <option value="8">8 por página</option>
        <option value="16">16 por página</option>
        <option value="24">24 por página</option>
      </select>
    </form>
    <form class="filter-choice select-form">
      <span class="title">Orden</span>
      <select v-model="sortMethod" title="sort-by" class="chosen-select" @change="changeSortMethod">
        <option value="abc">A &#10132; Z</option>
        <option value="zwy">Z &#10132; A</option>
        <option value="new">Nuevos &#10132; Antiguos</option>
        <option value="old">Antiguos &#10132; Nuevos</option>

        <option v-show="showPrice" value="minmax">Menor $ &#10132; Mayor $</option>
        <option v-show="showPrice" value="maxmin">Mayor $ &#10132; Menor $</option>

        <option v-show="showPrice && configuration.show_pricelists==1" value="MinMax">
            Menor $ &#10132; Mayor $ (Mayorista)
        </option>
        <option v-show="showPrice && configuration.show_pricelists==1" value="MaxMin">
            Mayor $ &#10132; Menor $ (Mayorista)
        </option>

      </select>
    </form>
    <div class=" boton-ir-filtros">
      <button class="single_add_to_cart_button btn btn-sm" @click="scrollToSection">Ver filtros</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueScrollTo from "vue-scrollto";
export default {
  name: "ShopSortControl",
  props: ["productsOnPages", "defaultSortMethod"],
  data() {
    return {
      itemCount: '8',
      sortMethod: 'abc'
    }
  },
  mounted() {
    this.itemCount = this.productsOnPages;
    this.sortMethod = this.defaultSortMethod
  },
  computed: {
    ...mapGetters({
      user: "ecommerce/user",
      configuration: "ecommerce/configuration"
    }),
    showPrice: {
        get: function () {
            if(this.configuration.show_public_prices){
                return true;
            }else{
                if(this.user!=null){
                    return true;
                }
            }
            return false;
        }
    },
  },
  methods: {
    changeQuantity: function() {
      this.$emit("changeQuantity", parseInt(this.itemCount));
    },
    changeSortMethod: function() {
      this.$emit("changeSortMethod", this.sortMethod);
    },
    scrollToSection() {
      VueScrollTo.scrollTo("#widgetFiltros", 300, { offset: -100 });
    }
  },
};
</script>

<style scoped>
.chosen-container {
  vertical-align: top;
  font-size: inherit;
  line-height: inherit;
  text-align: left;
  width: auto !important;
}
.shop-top-control {
  margin-bottom: 30px;
}
.boton-ir-filtros {
    display: none;
  }
@media (max-width: 991px) {
  .boton-ir-filtros {
    display: block;
    padding: 0 13px;
    margin: 15px 0;
  }
}
</style>
