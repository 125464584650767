<template>
  <div v-if="hasFilters" class="widget widget-categories">
    <div class="mb-3">
      <h3 class="widgettitle">Filtros</h3>
      <button type="button" class="single_add_to_cart_button btn-block btn-sm button" @click="clearAllFilters"> Borrar filtros</button>
    </div>
    

    <ul class="list-filters">
      <li v-if="searchdata.q!=''">
        <label class="tag">{{searchdata.q}} 
          <i @click="removeQuery" class="icon fa fa-times-circle pointer"></i>
        </label>
      </li>
      <li v-for="category in categoryNames" :key="category.id">
        <label class="tag">{{category.name}} 
          <i @click="removeCategory(category.id)" class="icon fa fa-times-circle pointer"></i>
        </label>
      </li>
      <li v-for="brand in brandNames" :key="brand.id">
        <label class="tag">{{brand.name}} 
          <i @click="removeBrand(brand.id)" class="icon fa fa-times-circle pointer"></i>
        </label>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      q:'',
      outData:{
        uid: this.$route.params.uid,
        catID:null,
      },
    };
  },
  methods: {
    ...mapActions({
      loadProducts: 'ecommerce/loadProducts',
      loadAttGroups: 'ecommerce/loadAttributeList',
      loadCatalog: 'ecommerce/loadCatalog'
    }),
    ...mapMutations({
      removeFilterCategory: 'ecommerce/REMOVE_FILTER_CATEGORY',
      setPageNumber: 'ecommerce/SET_PAGE_NUMBER',
      setQuery: 'ecommerce/SET_QUERY',
      removeFilterBrands: 'ecommerce/REMOVE_FILTER_BRANDS'
    }),
    removeCategory(id) {
      this.removeFilterCategory(id);
      this.setPageNumber(1);
      if (this.$route.name =='Catalog'){
        this.$store.dispatch("ecommerce/loadCatalog", this.outData);
      }else {
        this.loadProducts();
      }
      this.loadProducts();
    },
    removeBrand(id) {
      this.removeFilterBrands(id);
      this.setPageNumber(1);
      this.loadProducts();
    },
    removeQuery() {
      this.setQuery('');
      this.setPageNumber(1);
      if (this.$route.name =='Catalog'){
        this.$store.dispatch("ecommerce/loadCatalog", this.outData);
      }else {
        this.loadProducts();
      }
    },
    clearAllFilters() {
      this.categoryNames.forEach((category) => {
        this.removeFilterCategory(category.id);
      })
      this.brandNames.forEach((brand) => {
        this.removeFilterBrands(brand.id);
      })
      this.setPageNumber(1);
      this.setQuery('');
      if (this.$route.name =='Catalog'){
        this.$store.dispatch("ecommerce/loadCatalog", this.outData);
      }else {
        this.loadProducts();
      }
    }
  },
  computed: {
    ...mapGetters({
      categoryList: "ecommerce/categoryList",
      brands: "ecommerce/brands",
      searchdata: "ecommerce/searchdata"
    }),
    categoryNames() {
      let app = this
      return app.categoryList.filter(function (e) {
        return app.searchdata.filters.category.includes(e.id);
      });
    },
    brandNames() {
      let app = this
      return app.brands.filter(function (e) {
        return app.searchdata.filters.brand.includes(e.id);
      });
    },
    hasFilters() {
      return (this.categoryNames.length>0 || this.brandNames.length>0 || this.searchdata.q!='')
    }
  }
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
